import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { StyledContainer, Product, ProductContent } from "./styles"

SwiperCore.use([Navigation, Pagination])

const Products = () => {
  const data = useStaticQuery(graphql`
    query {
      estantegaveteirog4cinza: file(
        relativePath: { eq: "products/estante-gaveteiro-g4-cinza.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estantegaveteirog4cinza2: file(
        relativePath: { eq: "products/estante-gaveteiro-g4-cinza2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estantegaveteirog4cinza3: file(
        relativePath: { eq: "products/estante-gaveteiro-g4-cinza3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estantegaveteirog4cinza4: file(
        relativePath: { eq: "products/estante-gaveteiro-g4-cinza4.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estantegaveteirog4cinza5: file(
        relativePath: { eq: "products/estante-gaveteiro-g4-cinza5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estantegaveteiro36g1: file(
        relativePath: { eq: "products/estante-gaveteiro-36g-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estantegaveteiro36g2: file(
        relativePath: { eq: "products/estante-gaveteiro-36g-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estantegaveteiro36g4: file(
        relativePath: { eq: "products/estante-gaveteiro-36g-4.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estantegaveteiro36g5: file(
        relativePath: { eq: "products/estante-gaveteiro-36g-5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estantegaveteirobaixo: file(
        relativePath: { eq: "products/estante-gaveteiro-baixo.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledContainer as="section">
      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.estantegaveteirog4cinza.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.estantegaveteirog4cinza2.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.estantegaveteirog4cinza3.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.estantegaveteirog4cinza4.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.estantegaveteirog4cinza5.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Estante Gaveteiro G4</h3>

          <p>
            Estante em aço cinza. Fabricação Própria. Fabricamos nas chapas: 24
            e 22.
          </p>

          <ul>
            <li>Cores: Preto, cinza, branco e cores mistas.</li>
            <li>Chapa: 24 / 0.60mm ou 22 / 0.75mm</li>
            <li>Pintura: a pó eletrostática texturizada.</li>
            <li>Altura: 188 cm </li>
            <li>Largura: 92 cm</li>
            <li>Profundidade: 30 cm</li>
            <li>Peso: 60 kg</li>
          </ul>

          <p className="item-price">
            {/* R$ 2.100,<span>00</span> */}
            Consulte valor
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small>
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.estantegaveteiro36g1.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.estantegaveteiro36g2.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.estantegaveteiro36g4.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.estantegaveteiro36g5.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Estante Gaveteiro 36 gavetas</h3>

          <p>
            Estante em aço. Fabricação Própria. Fabricamos nas chapas: 24 e 22.
          </p>

          <ul>
            <li>Chapa: 24 / 0.60mm ou 22 / 0.75mm</li>
            <li>Pintura: a pó eletrostática texturizada.</li>
            <li>Altura: 198 cm </li>
            <li>Largura: 92 cm</li>
            <li>Profundidade: 30 cm</li>
          </ul>

          <p className="item-price">
            {/* R$ 3.200,<span>00</span> */}
            Consulte valor
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small>
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.estantegaveteirobaixo.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Estante Gaveteiro Baixo</h3>

          <p>
            Estante em aço. Fabricação Própria. Fabricamos nas chapas: 24 e 22.
          </p>

          <ul>
            <li>Chapa: 24 / 0.60mm ou 22 / 0.75mm</li>
            <li>Pintura: a pó eletrostática texturizada.</li>
            <li>Altura: 100 cm </li>
            <li>Largura: 92 cm</li>
            <li>Profundidade: 30 cm</li>
          </ul>

          <p className="item-price">
            {/* R$ 1.750,<span>00</span> */}
            Consulte valor
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small>
        </ProductContent>
      </Product>
    </StyledContainer>
  )
}

export default Products
