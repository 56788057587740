import React from "react"

import SEO from "../components/seo"

import ProductsArmariosEstantes from "../components/products/products-armarios-estantes"

const ArmarioEstantes = () => (
  <>
    <SEO title="Armários Estantes" />

    <ProductsArmariosEstantes />
  </>
)

export default ArmarioEstantes
